
import { acceptHMRUpdate, defineStore, skipHydrate } from 'pinia'
// import { useLocalStorage } from '@vueuse/core'
import { useLocalStorage } from '@vueuse/core'


const defaultData = ref({
  theme:process.env.DEFAULT_THEME,
  selectedShippingInfo:{},
  // selectedBillingInfo:{},
  //converting these to arrays of shipping/billing profiles, users will need to be able to create, modify, rearrange, and delete these profiles
  shippingInfo: [/*{
    firstName:'',
    lastName:'',
    country: "US", //we are defaulting to the US
    region: '',
    address1:'',
    address2:'',
    city:'',
    zip:''
  }*/],
  userInfo: {
    displayName:'',
    firstName: '',
    lastName: '',
    email:'',
    phone:'',
    uid:'',
    inVault: false,
    anon:true
  },
  wishes:[
    // {
    //   productId:'example', //should this be a printify id, or firebase?
    //   title:'Example Product',
    //   variantId: 'examplevar', //this is a printify generated ID
    //   variantTitle:'Example Variant',
    //   photoURL:'',
    // }
  ],
  orders:[
    //just a list of order id's
    //TODO - Need a way to manually refresh this list by querying firestore orders collection where owner==uid
  ],
  cart:[
    // {
    //   productId:'',
    //   productTitle:'',
    //   variantId:'',
    //   variantTitle:'',
    //   photoURL:'',
    //   quantity:1,
    //   price:999,
    //   shipCost:199
    // }
  ],
  isPartner:false,
  isFulfiller:false,
  isStripeConnected:false
})
// const user = useCurrentUser()
export const useUserDataStore = defineStore('userData', () => {
  const userData = useLocalStorage("userData", defaultData.value)
  // const userData = await useStorage().setItem('userData',defaultData)
  return { userData: skipHydrate(userData) }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserDataStore, import.meta.hot))
}