

export const isDev = false
export function vuetifyConfiguration() {
  const options = {"theme":{"defaultTheme":"myPurpleTheme","variations":{"colors":["primary","secondary","surface"],"lighten":3,"darken":3},"themes":{"myPurpleTheme":{"colors":{"background":"#FFF5F5","whitebg":"ffffff","surface":"#FFF9F9","surface-darken-1":"#F0F0F0","surface-normal-1":"#FFECEE","primary":"#A683B1","primary-darken-1":"#4C1973","secondary":"#bef67a","secondary-darken-1":"#8bc34a","error":"#B00020","fieldtext":"#4C1973"},"dark":false},"myDarkTheme":{"dark":true,"colors":{"primary":"#A683B1","secondary":"#AAFFE6","background":"#302433","surface":"#151016","surface-darken-1":"#F0F0F0","surface-normal-1":"#FFECEE","primary-darken-1":"#4C1973","secondary-darken-1":"#8bc34a","error":"#B00020","fieldtext":"#A683B1"}},"myNeutralTheme":{"colors":{"background":"#FFEAD8","whitebg":"ffffff","surface":"#FFFAD8","primary":"#D98997","secondary":"#CD9868","error":"#B00020"}}}},"display":{"mobileBreakpoint":"sm","thresholds":{"xs":0,"sm":640,"md":768,"lg":1024,"xl":1536}},"defaults":{"VSelect":{"variant":"outlined"},"VTextField":{"variant":"outlined"},"VCombobox":{"variant":"outlined"},"VFileInput":{"variant":"outlined"},"VTextarea":{"variant":"outlined"},"VAutocomplete":{"variant":"outlined"}},"ssr":true}
  
  
  
  
  return options
}

