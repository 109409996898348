import payload_plugin_e0k4tW9bdI from "C:/Users/Administrator/Desktop/lsdope/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.mjs";
import revive_payload_client_4sVQNw7RlN from "C:/Users/Administrator/Desktop/lsdope/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_RMzWu406ID from "C:/Users/Administrator/Desktop/lsdope/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import unhead_KgADcZ0jPj from "C:/Users/Administrator/Desktop/lsdope/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/Users/Administrator/Desktop/lsdope/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "C:/Users/Administrator/Desktop/lsdope/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "C:/Users/Administrator/Desktop/lsdope/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "C:/Users/Administrator/Desktop/lsdope/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "C:/Users/Administrator/Desktop/lsdope/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "C:/Users/Administrator/Desktop/lsdope/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "C:/Users/Administrator/Desktop/lsdope/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_no_client_hints_wMKVLl9fyU from "C:/Users/Administrator/Desktop/lsdope/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import plugin_BqLkNUef68 from "C:/Users/Administrator/Desktop/lsdope/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_t2GMTTFnMT from "C:/Users/Administrator/Desktop/lsdope/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_client_LcKgStRyi6 from "C:/Users/Administrator/Desktop/lsdope/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_client_mik7N71GmK from "C:/Users/Administrator/Desktop/lsdope/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import vuefire_auth_plugin_client_cCwkwh1j5u from "C:/Users/Administrator/Desktop/lsdope/.nuxt/vuefire-auth-plugin.client.mjs";
import plugin_mint_cookie_client_xgxXjNHXgE from "C:/Users/Administrator/Desktop/lsdope/node_modules/nuxt-vuefire/dist/runtime/auth/plugin-mint-cookie.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "C:/Users/Administrator/Desktop/lsdope/.nuxt/vuefire-plugin.mjs";
import chunk_reload_client_UciE0i6zes from "C:/Users/Administrator/Desktop/lsdope/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "C:/Users/Administrator/Desktop/lsdope/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  payload_plugin_e0k4tW9bdI,
  revive_payload_client_4sVQNw7RlN,
  vuetify_icons_RMzWu406ID,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  vuetify_no_client_hints_wMKVLl9fyU,
  plugin_BqLkNUef68,
  plugin_t2GMTTFnMT,
  plugin_client_LcKgStRyi6,
  plugin_client_mik7N71GmK,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  plugin_mint_cookie_client_xgxXjNHXgE,
  vuefire_plugin_8cq4FnzXKb,
  chunk_reload_client_UciE0i6zes,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]