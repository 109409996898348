import { default as accountqfvplzmigRMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/account.vue?macro=true";
import { default as indexu0xfmtkVnyMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/brevo/index.vue?macro=true";
import { default as order_45shipment_45createdl3bI7yC65aMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/brevo/order-shipment-created.vue?macro=true";
import { default as order_45shipment_45deliveredqgFonfRqOCMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/brevo/order-shipment-delivered.vue?macro=true";
import { default as order_45successfWiCKipmZbMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/brevo/order-success.vue?macro=true";
import { default as _91id_939oZjMiuRNTMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/cases/[id].vue?macro=true";
import { default as indexVkMfztyMlHMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/cases/index.vue?macro=true";
import { default as config4tAJTb16bGMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/config.vue?macro=true";
import { default as respondZlLYKOBO4hMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/contact/respond.vue?macro=true";
import { default as indexpZzOo6lLTrMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/fulfillers/index.vue?macro=true";
import { default as indexZS0r8SfC0dMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/index.vue?macro=true";
import { default as indexIP55yGssRgMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/jobs/index.vue?macro=true";
import { default as status_45overviewDSPfFXPsjzMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/orders/status-overview.vue?macro=true";
import { default as _91status_93SDs9ttFusGMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/orders/status/[status].vue?macro=true";
import { default as indexQdRzyDxWBrMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/orders/status/index.vue?macro=true";
import { default as indexujzZi7TrmHMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/partners/applicants/index.vue?macro=true";
import { default as indexKRiR5Hn7tvMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/partners/index.vue?macro=true";
import { default as imagesR24zAqnq3xMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/printify/images.vue?macro=true";
import { default as indexoyShwpNaAQMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/printify/orders/index.vue?macro=true";
import { default as index1PsoKxe1uyMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/printify/products/index.vue?macro=true";
import { default as manual_45publish_45faileLhuWY4FzmMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/printify/products/manual-publish-fail.vue?macro=true";
import { default as manual_45unpublishHG1sQ6Jz0hMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/printify/products/manual-unpublish.vue?macro=true";
import { default as indexGyCFntre1VMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/products/index.vue?macro=true";
import { default as setRoleByUidUnOCi9hP1QMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/users/setRoleByUid.vue?macro=true";
import { default as indexkxT6wrZ5UHMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/webhooks/printify/index.vue?macro=true";
import { default as indexsvuY3t3LJeMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/admin/wishes/index.vue?macro=true";
import { default as ashwrxGPmDHtWMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/ash.vue?macro=true";
import { default as checkoutEY0eWHeXaKMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/checkout.vue?macro=true";
import { default as contactO9ZrGRBi25Meta } from "C:/Users/Administrator/Desktop/lsdope/pages/contact.vue?macro=true";
import { default as indexUSPUpcfCmPMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/index.vue?macro=true";
import { default as _91id_93O4qqsshPKmMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/jobs/[id].vue?macro=true";
import { default as generalQwg462iS1fMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/jobs/general.vue?macro=true";
import { default as index7htnVns9npMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/jobs/index.vue?macro=true";
import { default as disclosuresaysqFGuc8XMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/legal/disclosures.vue?macro=true";
import { default as indexGVf9mDHWjVMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/legal/index.vue?macro=true";
import { default as manufacturingTermsjxNiZGDsMwMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/legal/manufacturingTerms.vue?macro=true";
import { default as partnerTermsQ7rOiPIiTDMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/legal/partnerTerms.vue?macro=true";
import { default as privacyPolicyjYxUWfA58tMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/legal/privacyPolicy.vue?macro=true";
import { default as termsAndConditionsU7LRttSwH7Meta } from "C:/Users/Administrator/Desktop/lsdope/pages/legal/termsAndConditions.vue?macro=true";
import { default as login4lXiN0SpmQMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/login.vue?macro=true";
import { default as logoutybfeQFce25Meta } from "C:/Users/Administrator/Desktop/lsdope/pages/logout.vue?macro=true";
import { default as paymentUUlRtBc8VKMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/payment.vue?macro=true";
import { default as product_45requestaXQABlAaH3Meta } from "C:/Users/Administrator/Desktop/lsdope/pages/product-request.vue?macro=true";
import { default as searchiP5kqZBx6WMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/search.vue?macro=true";
import { default as faqsdqhVcrbvzAMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/faqs.vue?macro=true";
import { default as indexdgv6yZ02FSMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/index.vue?macro=true";
import { default as _91id_93XCDEtFtTUMMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/orders/[id].vue?macro=true";
import { default as index77mGnxJKwPMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/administration/analytics/index.vue?macro=true";
import { default as connect_45stripeN0du6rklGlMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/administration/connect-stripe.vue?macro=true";
import { default as getting_45startedviYEScyyqrMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/administration/getting-started.vue?macro=true";
import { default as indexTrWgTufT9AMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/administration/index.vue?macro=true";
import { default as createa6PADlTsCqMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/administration/products/create.vue?macro=true";
import { default as indexSger8ns1QBMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/administration/products/index.vue?macro=true";
import { default as profileskm1XbnuBZMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/administration/profile.vue?macro=true";
import { default as workerprofileRc36mNL0pJMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/administration/workerprofile.vue?macro=true";
import { default as fanClubECBgtA5VsCMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fanClub.vue?macro=true";
import { default as fulfillment_45applicationkUML7GewmiMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment-application.vue?macro=true";
import { default as indexCF2sO3ENRPMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/analytics/index.vue?macro=true";
import { default as carriers3IhiK61n2vMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/carriers.vue?macro=true";
import { default as indexO5w02uJvZMMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/cases/index.vue?macro=true";
import { default as indexAnbHHlp63hMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/config/index.vue?macro=true";
import { default as getting_45startedRdWXpp6kGwMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/getting-started.vue?macro=true";
import { default as indexPUpft01QJkMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/index.vue?macro=true";
import { default as indexhqLPuhAYswMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/orders/index.vue?macro=true";
import { default as indexvLNdPmkcalMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/warehouses/[warehouseName]/batches/index.vue?macro=true";
import { default as index967hFEmh7EMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/warehouses/[warehouseName]/cases/index.vue?macro=true";
import { default as indexOfT7Z4KYaXMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/warehouses/[warehouseName]/manifests/index.vue?macro=true";
import { default as indexuwuleru8e9Meta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/warehouses/[warehouseName]/parcels/index.vue?macro=true";
import { default as indexc3yFMsRq9OMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/warehouses/[warehouseName]/shipments/index.vue?macro=true";
import { default as createL8uG5IabbhMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/warehouses/create.vue?macro=true";
import { default as index7rfPPiUDqYMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/warehouses/index.vue?macro=true";
import { default as indexCo3Io1X6tQMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/index.vue?macro=true";
import { default as register9hzCUwMdOxMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/register.vue?macro=true";
import { default as termsFxt0N3s1YFMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/terms.vue?macro=true";
import { default as index37C6gatoJEMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/warehouses/[id].vue/index.vue?macro=true";
import { default as index2TaIOUUaaIMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/index.vue?macro=true";
import { default as indexM76ro9yObpMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/partners/index.vue?macro=true";
import { default as _91category_93UmOfGE4w6tMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/products/[category].vue?macro=true";
import { default as index4V1QD4dMUXMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/products/index.vue?macro=true";
import { default as registerckrmyHSAzhMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/register.vue?macro=true";
import { default as termsSK6XvqXSx1Meta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/terms.vue?macro=true";
import { default as _91id_93DjVsKdZ4vIMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/product/[id].vue?macro=true";
import { default as _91category_93OdJeGkDxjcMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/products/[category].vue?macro=true";
import { default as indexJxBz4rn3XZMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/shoppers/index.vue?macro=true";
import { default as indexVDBd912gKIMeta } from "C:/Users/Administrator/Desktop/lsdope/pages/shop/stores/@[name]/index.vue?macro=true";
export default [
  {
    name: accountqfvplzmigRMeta?.name ?? "account",
    path: accountqfvplzmigRMeta?.path ?? "/account",
    meta: accountqfvplzmigRMeta || {},
    alias: accountqfvplzmigRMeta?.alias || [],
    redirect: accountqfvplzmigRMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/account.vue").then(m => m.default || m)
  },
  {
    name: indexu0xfmtkVnyMeta?.name ?? "admin-brevo",
    path: indexu0xfmtkVnyMeta?.path ?? "/admin/brevo",
    meta: indexu0xfmtkVnyMeta || {},
    alias: indexu0xfmtkVnyMeta?.alias || [],
    redirect: indexu0xfmtkVnyMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/brevo/index.vue").then(m => m.default || m)
  },
  {
    name: order_45shipment_45createdl3bI7yC65aMeta?.name ?? "admin-brevo-order-shipment-created",
    path: order_45shipment_45createdl3bI7yC65aMeta?.path ?? "/admin/brevo/order-shipment-created",
    meta: order_45shipment_45createdl3bI7yC65aMeta || {},
    alias: order_45shipment_45createdl3bI7yC65aMeta?.alias || [],
    redirect: order_45shipment_45createdl3bI7yC65aMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/brevo/order-shipment-created.vue").then(m => m.default || m)
  },
  {
    name: order_45shipment_45deliveredqgFonfRqOCMeta?.name ?? "admin-brevo-order-shipment-delivered",
    path: order_45shipment_45deliveredqgFonfRqOCMeta?.path ?? "/admin/brevo/order-shipment-delivered",
    meta: order_45shipment_45deliveredqgFonfRqOCMeta || {},
    alias: order_45shipment_45deliveredqgFonfRqOCMeta?.alias || [],
    redirect: order_45shipment_45deliveredqgFonfRqOCMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/brevo/order-shipment-delivered.vue").then(m => m.default || m)
  },
  {
    name: order_45successfWiCKipmZbMeta?.name ?? "admin-brevo-order-success",
    path: order_45successfWiCKipmZbMeta?.path ?? "/admin/brevo/order-success",
    meta: order_45successfWiCKipmZbMeta || {},
    alias: order_45successfWiCKipmZbMeta?.alias || [],
    redirect: order_45successfWiCKipmZbMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/brevo/order-success.vue").then(m => m.default || m)
  },
  {
    name: _91id_939oZjMiuRNTMeta?.name ?? "admin-cases-id",
    path: _91id_939oZjMiuRNTMeta?.path ?? "/admin/cases/:id()",
    meta: _91id_939oZjMiuRNTMeta || {},
    alias: _91id_939oZjMiuRNTMeta?.alias || [],
    redirect: _91id_939oZjMiuRNTMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/cases/[id].vue").then(m => m.default || m)
  },
  {
    name: indexVkMfztyMlHMeta?.name ?? "admin-cases",
    path: indexVkMfztyMlHMeta?.path ?? "/admin/cases",
    meta: indexVkMfztyMlHMeta || {},
    alias: indexVkMfztyMlHMeta?.alias || [],
    redirect: indexVkMfztyMlHMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/cases/index.vue").then(m => m.default || m)
  },
  {
    name: config4tAJTb16bGMeta?.name ?? "admin-config",
    path: config4tAJTb16bGMeta?.path ?? "/admin/config",
    meta: config4tAJTb16bGMeta || {},
    alias: config4tAJTb16bGMeta?.alias || [],
    redirect: config4tAJTb16bGMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/config.vue").then(m => m.default || m)
  },
  {
    name: respondZlLYKOBO4hMeta?.name ?? "admin-contact-respond",
    path: respondZlLYKOBO4hMeta?.path ?? "/admin/contact/respond",
    meta: respondZlLYKOBO4hMeta || {},
    alias: respondZlLYKOBO4hMeta?.alias || [],
    redirect: respondZlLYKOBO4hMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/contact/respond.vue").then(m => m.default || m)
  },
  {
    name: indexpZzOo6lLTrMeta?.name ?? "admin-fulfillers",
    path: indexpZzOo6lLTrMeta?.path ?? "/admin/fulfillers",
    meta: indexpZzOo6lLTrMeta || {},
    alias: indexpZzOo6lLTrMeta?.alias || [],
    redirect: indexpZzOo6lLTrMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/fulfillers/index.vue").then(m => m.default || m)
  },
  {
    name: indexZS0r8SfC0dMeta?.name ?? "admin",
    path: indexZS0r8SfC0dMeta?.path ?? "/admin",
    meta: indexZS0r8SfC0dMeta || {},
    alias: indexZS0r8SfC0dMeta?.alias || [],
    redirect: indexZS0r8SfC0dMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexIP55yGssRgMeta?.name ?? "admin-jobs",
    path: indexIP55yGssRgMeta?.path ?? "/admin/jobs",
    meta: indexIP55yGssRgMeta || {},
    alias: indexIP55yGssRgMeta?.alias || [],
    redirect: indexIP55yGssRgMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: status_45overviewDSPfFXPsjzMeta?.name ?? "admin-orders-status-overview",
    path: status_45overviewDSPfFXPsjzMeta?.path ?? "/admin/orders/status-overview",
    meta: status_45overviewDSPfFXPsjzMeta || {},
    alias: status_45overviewDSPfFXPsjzMeta?.alias || [],
    redirect: status_45overviewDSPfFXPsjzMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/orders/status-overview.vue").then(m => m.default || m)
  },
  {
    name: _91status_93SDs9ttFusGMeta?.name ?? "admin-orders-status-status",
    path: _91status_93SDs9ttFusGMeta?.path ?? "/admin/orders/status/:status()",
    meta: _91status_93SDs9ttFusGMeta || {},
    alias: _91status_93SDs9ttFusGMeta?.alias || [],
    redirect: _91status_93SDs9ttFusGMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/orders/status/[status].vue").then(m => m.default || m)
  },
  {
    name: indexQdRzyDxWBrMeta?.name ?? "admin-orders-status",
    path: indexQdRzyDxWBrMeta?.path ?? "/admin/orders/status",
    meta: indexQdRzyDxWBrMeta || {},
    alias: indexQdRzyDxWBrMeta?.alias || [],
    redirect: indexQdRzyDxWBrMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/orders/status/index.vue").then(m => m.default || m)
  },
  {
    name: indexujzZi7TrmHMeta?.name ?? "admin-partners-applicants",
    path: indexujzZi7TrmHMeta?.path ?? "/admin/partners/applicants",
    meta: indexujzZi7TrmHMeta || {},
    alias: indexujzZi7TrmHMeta?.alias || [],
    redirect: indexujzZi7TrmHMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/partners/applicants/index.vue").then(m => m.default || m)
  },
  {
    name: indexKRiR5Hn7tvMeta?.name ?? "admin-partners",
    path: indexKRiR5Hn7tvMeta?.path ?? "/admin/partners",
    meta: indexKRiR5Hn7tvMeta || {},
    alias: indexKRiR5Hn7tvMeta?.alias || [],
    redirect: indexKRiR5Hn7tvMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/partners/index.vue").then(m => m.default || m)
  },
  {
    name: imagesR24zAqnq3xMeta?.name ?? "admin-printify-images",
    path: imagesR24zAqnq3xMeta?.path ?? "/admin/printify/images",
    meta: imagesR24zAqnq3xMeta || {},
    alias: imagesR24zAqnq3xMeta?.alias || [],
    redirect: imagesR24zAqnq3xMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/printify/images.vue").then(m => m.default || m)
  },
  {
    name: indexoyShwpNaAQMeta?.name ?? "admin-printify-orders",
    path: indexoyShwpNaAQMeta?.path ?? "/admin/printify/orders",
    meta: indexoyShwpNaAQMeta || {},
    alias: indexoyShwpNaAQMeta?.alias || [],
    redirect: indexoyShwpNaAQMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/printify/orders/index.vue").then(m => m.default || m)
  },
  {
    name: index1PsoKxe1uyMeta?.name ?? "admin-printify-products",
    path: index1PsoKxe1uyMeta?.path ?? "/admin/printify/products",
    meta: index1PsoKxe1uyMeta || {},
    alias: index1PsoKxe1uyMeta?.alias || [],
    redirect: index1PsoKxe1uyMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/printify/products/index.vue").then(m => m.default || m)
  },
  {
    name: manual_45publish_45faileLhuWY4FzmMeta?.name ?? "admin-printify-products-manual-publish-fail",
    path: manual_45publish_45faileLhuWY4FzmMeta?.path ?? "/admin/printify/products/manual-publish-fail",
    meta: manual_45publish_45faileLhuWY4FzmMeta || {},
    alias: manual_45publish_45faileLhuWY4FzmMeta?.alias || [],
    redirect: manual_45publish_45faileLhuWY4FzmMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/printify/products/manual-publish-fail.vue").then(m => m.default || m)
  },
  {
    name: manual_45unpublishHG1sQ6Jz0hMeta?.name ?? "admin-printify-products-manual-unpublish",
    path: manual_45unpublishHG1sQ6Jz0hMeta?.path ?? "/admin/printify/products/manual-unpublish",
    meta: manual_45unpublishHG1sQ6Jz0hMeta || {},
    alias: manual_45unpublishHG1sQ6Jz0hMeta?.alias || [],
    redirect: manual_45unpublishHG1sQ6Jz0hMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/printify/products/manual-unpublish.vue").then(m => m.default || m)
  },
  {
    name: indexGyCFntre1VMeta?.name ?? "admin-products",
    path: indexGyCFntre1VMeta?.path ?? "/admin/products",
    meta: indexGyCFntre1VMeta || {},
    alias: indexGyCFntre1VMeta?.alias || [],
    redirect: indexGyCFntre1VMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/products/index.vue").then(m => m.default || m)
  },
  {
    name: setRoleByUidUnOCi9hP1QMeta?.name ?? "admin-users-setRoleByUid",
    path: setRoleByUidUnOCi9hP1QMeta?.path ?? "/admin/users/setRoleByUid",
    meta: setRoleByUidUnOCi9hP1QMeta || {},
    alias: setRoleByUidUnOCi9hP1QMeta?.alias || [],
    redirect: setRoleByUidUnOCi9hP1QMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/users/setRoleByUid.vue").then(m => m.default || m)
  },
  {
    name: indexkxT6wrZ5UHMeta?.name ?? "admin-webhooks-printify",
    path: indexkxT6wrZ5UHMeta?.path ?? "/admin/webhooks/printify",
    meta: indexkxT6wrZ5UHMeta || {},
    alias: indexkxT6wrZ5UHMeta?.alias || [],
    redirect: indexkxT6wrZ5UHMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/webhooks/printify/index.vue").then(m => m.default || m)
  },
  {
    name: indexsvuY3t3LJeMeta?.name ?? "admin-wishes",
    path: indexsvuY3t3LJeMeta?.path ?? "/admin/wishes",
    meta: indexsvuY3t3LJeMeta || {},
    alias: indexsvuY3t3LJeMeta?.alias || [],
    redirect: indexsvuY3t3LJeMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/admin/wishes/index.vue").then(m => m.default || m)
  },
  {
    name: ashwrxGPmDHtWMeta?.name ?? "ash",
    path: ashwrxGPmDHtWMeta?.path ?? "/ash",
    meta: ashwrxGPmDHtWMeta || {},
    alias: ashwrxGPmDHtWMeta?.alias || [],
    redirect: ashwrxGPmDHtWMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/ash.vue").then(m => m.default || m)
  },
  {
    name: checkoutEY0eWHeXaKMeta?.name ?? "checkout",
    path: checkoutEY0eWHeXaKMeta?.path ?? "/checkout",
    meta: checkoutEY0eWHeXaKMeta || {},
    alias: checkoutEY0eWHeXaKMeta?.alias || [],
    redirect: checkoutEY0eWHeXaKMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: contactO9ZrGRBi25Meta?.name ?? "contact",
    path: contactO9ZrGRBi25Meta?.path ?? "/contact",
    meta: contactO9ZrGRBi25Meta || {},
    alias: contactO9ZrGRBi25Meta?.alias || [],
    redirect: contactO9ZrGRBi25Meta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: indexUSPUpcfCmPMeta?.name ?? "index",
    path: indexUSPUpcfCmPMeta?.path ?? "/",
    meta: indexUSPUpcfCmPMeta || {},
    alias: indexUSPUpcfCmPMeta?.alias || [],
    redirect: indexUSPUpcfCmPMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93O4qqsshPKmMeta?.name ?? "jobs-id",
    path: _91id_93O4qqsshPKmMeta?.path ?? "/jobs/:id()",
    meta: _91id_93O4qqsshPKmMeta || {},
    alias: _91id_93O4qqsshPKmMeta?.alias || [],
    redirect: _91id_93O4qqsshPKmMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/jobs/[id].vue").then(m => m.default || m)
  },
  {
    name: generalQwg462iS1fMeta?.name ?? "jobs-general",
    path: generalQwg462iS1fMeta?.path ?? "/jobs/general",
    meta: generalQwg462iS1fMeta || {},
    alias: generalQwg462iS1fMeta?.alias || [],
    redirect: generalQwg462iS1fMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/jobs/general.vue").then(m => m.default || m)
  },
  {
    name: index7htnVns9npMeta?.name ?? "jobs",
    path: index7htnVns9npMeta?.path ?? "/jobs",
    meta: index7htnVns9npMeta || {},
    alias: index7htnVns9npMeta?.alias || [],
    redirect: index7htnVns9npMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: disclosuresaysqFGuc8XMeta?.name ?? "legal-disclosures",
    path: disclosuresaysqFGuc8XMeta?.path ?? "/legal/disclosures",
    meta: disclosuresaysqFGuc8XMeta || {},
    alias: disclosuresaysqFGuc8XMeta?.alias || [],
    redirect: disclosuresaysqFGuc8XMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/legal/disclosures.vue").then(m => m.default || m)
  },
  {
    name: indexGVf9mDHWjVMeta?.name ?? "legal",
    path: indexGVf9mDHWjVMeta?.path ?? "/legal",
    meta: indexGVf9mDHWjVMeta || {},
    alias: indexGVf9mDHWjVMeta?.alias || [],
    redirect: indexGVf9mDHWjVMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/legal/index.vue").then(m => m.default || m)
  },
  {
    name: manufacturingTermsjxNiZGDsMwMeta?.name ?? "legal-manufacturingTerms",
    path: manufacturingTermsjxNiZGDsMwMeta?.path ?? "/legal/manufacturingTerms",
    meta: manufacturingTermsjxNiZGDsMwMeta || {},
    alias: manufacturingTermsjxNiZGDsMwMeta?.alias || [],
    redirect: manufacturingTermsjxNiZGDsMwMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/legal/manufacturingTerms.vue").then(m => m.default || m)
  },
  {
    name: partnerTermsQ7rOiPIiTDMeta?.name ?? "legal-partnerTerms",
    path: partnerTermsQ7rOiPIiTDMeta?.path ?? "/legal/partnerTerms",
    meta: partnerTermsQ7rOiPIiTDMeta || {},
    alias: partnerTermsQ7rOiPIiTDMeta?.alias || [],
    redirect: partnerTermsQ7rOiPIiTDMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/legal/partnerTerms.vue").then(m => m.default || m)
  },
  {
    name: privacyPolicyjYxUWfA58tMeta?.name ?? "legal-privacyPolicy",
    path: privacyPolicyjYxUWfA58tMeta?.path ?? "/legal/privacyPolicy",
    meta: privacyPolicyjYxUWfA58tMeta || {},
    alias: privacyPolicyjYxUWfA58tMeta?.alias || [],
    redirect: privacyPolicyjYxUWfA58tMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/legal/privacyPolicy.vue").then(m => m.default || m)
  },
  {
    name: termsAndConditionsU7LRttSwH7Meta?.name ?? "legal-termsAndConditions",
    path: termsAndConditionsU7LRttSwH7Meta?.path ?? "/legal/termsAndConditions",
    meta: termsAndConditionsU7LRttSwH7Meta || {},
    alias: termsAndConditionsU7LRttSwH7Meta?.alias || [],
    redirect: termsAndConditionsU7LRttSwH7Meta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/legal/termsAndConditions.vue").then(m => m.default || m)
  },
  {
    name: login4lXiN0SpmQMeta?.name ?? "login",
    path: login4lXiN0SpmQMeta?.path ?? "/login",
    meta: login4lXiN0SpmQMeta || {},
    alias: login4lXiN0SpmQMeta?.alias || [],
    redirect: login4lXiN0SpmQMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutybfeQFce25Meta?.name ?? "logout",
    path: logoutybfeQFce25Meta?.path ?? "/logout",
    meta: logoutybfeQFce25Meta || {},
    alias: logoutybfeQFce25Meta?.alias || [],
    redirect: logoutybfeQFce25Meta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: paymentUUlRtBc8VKMeta?.name ?? "payment",
    path: paymentUUlRtBc8VKMeta?.path ?? "/payment",
    meta: paymentUUlRtBc8VKMeta || {},
    alias: paymentUUlRtBc8VKMeta?.alias || [],
    redirect: paymentUUlRtBc8VKMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/payment.vue").then(m => m.default || m)
  },
  {
    name: product_45requestaXQABlAaH3Meta?.name ?? "product-request",
    path: product_45requestaXQABlAaH3Meta?.path ?? "/product-request",
    meta: product_45requestaXQABlAaH3Meta || {},
    alias: product_45requestaXQABlAaH3Meta?.alias || [],
    redirect: product_45requestaXQABlAaH3Meta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/product-request.vue").then(m => m.default || m)
  },
  {
    name: searchiP5kqZBx6WMeta?.name ?? "search",
    path: searchiP5kqZBx6WMeta?.path ?? "/search",
    meta: searchiP5kqZBx6WMeta || {},
    alias: searchiP5kqZBx6WMeta?.alias || [],
    redirect: searchiP5kqZBx6WMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/search.vue").then(m => m.default || m)
  },
  {
    name: faqsdqhVcrbvzAMeta?.name ?? "shop-faqs",
    path: faqsdqhVcrbvzAMeta?.path ?? "/shop/faqs",
    meta: faqsdqhVcrbvzAMeta || {},
    alias: faqsdqhVcrbvzAMeta?.alias || [],
    redirect: faqsdqhVcrbvzAMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/faqs.vue").then(m => m.default || m)
  },
  {
    name: indexdgv6yZ02FSMeta?.name ?? "shop",
    path: indexdgv6yZ02FSMeta?.path ?? "/shop",
    meta: indexdgv6yZ02FSMeta || {},
    alias: indexdgv6yZ02FSMeta?.alias || [],
    redirect: indexdgv6yZ02FSMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93XCDEtFtTUMMeta?.name ?? "shop-orders-id",
    path: _91id_93XCDEtFtTUMMeta?.path ?? "/shop/orders/:id()",
    meta: _91id_93XCDEtFtTUMMeta || {},
    alias: _91id_93XCDEtFtTUMMeta?.alias || [],
    redirect: _91id_93XCDEtFtTUMMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: index77mGnxJKwPMeta?.name ?? "shop-partners-@name-administration-analytics",
    path: index77mGnxJKwPMeta?.path ?? "/shop/partners/@:name()/administration/analytics",
    meta: index77mGnxJKwPMeta || {},
    alias: index77mGnxJKwPMeta?.alias || [],
    redirect: index77mGnxJKwPMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/administration/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: connect_45stripeN0du6rklGlMeta?.name ?? "shop-partners-@name-administration-connect-stripe",
    path: connect_45stripeN0du6rklGlMeta?.path ?? "/shop/partners/@:name()/administration/connect-stripe",
    meta: connect_45stripeN0du6rklGlMeta || {},
    alias: connect_45stripeN0du6rklGlMeta?.alias || [],
    redirect: connect_45stripeN0du6rklGlMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/administration/connect-stripe.vue").then(m => m.default || m)
  },
  {
    name: getting_45startedviYEScyyqrMeta?.name ?? "shop-partners-@name-administration-getting-started",
    path: getting_45startedviYEScyyqrMeta?.path ?? "/shop/partners/@:name()/administration/getting-started",
    meta: getting_45startedviYEScyyqrMeta || {},
    alias: getting_45startedviYEScyyqrMeta?.alias || [],
    redirect: getting_45startedviYEScyyqrMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/administration/getting-started.vue").then(m => m.default || m)
  },
  {
    name: indexTrWgTufT9AMeta?.name ?? "shop-partners-@name-administration",
    path: indexTrWgTufT9AMeta?.path ?? "/shop/partners/@:name()/administration",
    meta: indexTrWgTufT9AMeta || {},
    alias: indexTrWgTufT9AMeta?.alias || [],
    redirect: indexTrWgTufT9AMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/administration/index.vue").then(m => m.default || m)
  },
  {
    name: createa6PADlTsCqMeta?.name ?? "shop-partners-@name-administration-products-create",
    path: createa6PADlTsCqMeta?.path ?? "/shop/partners/@:name()/administration/products/create",
    meta: createa6PADlTsCqMeta || {},
    alias: createa6PADlTsCqMeta?.alias || [],
    redirect: createa6PADlTsCqMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/administration/products/create.vue").then(m => m.default || m)
  },
  {
    name: indexSger8ns1QBMeta?.name ?? "shop-partners-@name-administration-products",
    path: indexSger8ns1QBMeta?.path ?? "/shop/partners/@:name()/administration/products",
    meta: indexSger8ns1QBMeta || {},
    alias: indexSger8ns1QBMeta?.alias || [],
    redirect: indexSger8ns1QBMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/administration/products/index.vue").then(m => m.default || m)
  },
  {
    name: profileskm1XbnuBZMeta?.name ?? "shop-partners-@name-administration-profile",
    path: profileskm1XbnuBZMeta?.path ?? "/shop/partners/@:name()/administration/profile",
    meta: profileskm1XbnuBZMeta || {},
    alias: profileskm1XbnuBZMeta?.alias || [],
    redirect: profileskm1XbnuBZMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/administration/profile.vue").then(m => m.default || m)
  },
  {
    name: workerprofileRc36mNL0pJMeta?.name ?? "shop-partners-@name-administration-workerprofile",
    path: workerprofileRc36mNL0pJMeta?.path ?? "/shop/partners/@:name()/administration/workerprofile",
    meta: workerprofileRc36mNL0pJMeta || {},
    alias: workerprofileRc36mNL0pJMeta?.alias || [],
    redirect: workerprofileRc36mNL0pJMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/administration/workerprofile.vue").then(m => m.default || m)
  },
  {
    name: fanClubECBgtA5VsCMeta?.name ?? "shop-partners-@name-fanClub",
    path: fanClubECBgtA5VsCMeta?.path ?? "/shop/partners/@:name()/fanClub",
    meta: fanClubECBgtA5VsCMeta || {},
    alias: fanClubECBgtA5VsCMeta?.alias || [],
    redirect: fanClubECBgtA5VsCMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fanClub.vue").then(m => m.default || m)
  },
  {
    name: fulfillment_45applicationkUML7GewmiMeta?.name ?? "shop-partners-@name-fulfillment-application",
    path: fulfillment_45applicationkUML7GewmiMeta?.path ?? "/shop/partners/@:name()/fulfillment-application",
    meta: fulfillment_45applicationkUML7GewmiMeta || {},
    alias: fulfillment_45applicationkUML7GewmiMeta?.alias || [],
    redirect: fulfillment_45applicationkUML7GewmiMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment-application.vue").then(m => m.default || m)
  },
  {
    name: indexCF2sO3ENRPMeta?.name ?? "shop-partners-@name-fulfillment-administration-analytics",
    path: indexCF2sO3ENRPMeta?.path ?? "/shop/partners/@:name()/fulfillment/administration/analytics",
    meta: indexCF2sO3ENRPMeta || {},
    alias: indexCF2sO3ENRPMeta?.alias || [],
    redirect: indexCF2sO3ENRPMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: carriers3IhiK61n2vMeta?.name ?? "shop-partners-@name-fulfillment-administration-carriers",
    path: carriers3IhiK61n2vMeta?.path ?? "/shop/partners/@:name()/fulfillment/administration/carriers",
    meta: carriers3IhiK61n2vMeta || {},
    alias: carriers3IhiK61n2vMeta?.alias || [],
    redirect: carriers3IhiK61n2vMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/carriers.vue").then(m => m.default || m)
  },
  {
    name: indexO5w02uJvZMMeta?.name ?? "shop-partners-@name-fulfillment-administration-cases",
    path: indexO5w02uJvZMMeta?.path ?? "/shop/partners/@:name()/fulfillment/administration/cases",
    meta: indexO5w02uJvZMMeta || {},
    alias: indexO5w02uJvZMMeta?.alias || [],
    redirect: indexO5w02uJvZMMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/cases/index.vue").then(m => m.default || m)
  },
  {
    name: indexAnbHHlp63hMeta?.name ?? "shop-partners-@name-fulfillment-administration-config",
    path: indexAnbHHlp63hMeta?.path ?? "/shop/partners/@:name()/fulfillment/administration/config",
    meta: indexAnbHHlp63hMeta || {},
    alias: indexAnbHHlp63hMeta?.alias || [],
    redirect: indexAnbHHlp63hMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/config/index.vue").then(m => m.default || m)
  },
  {
    name: getting_45startedRdWXpp6kGwMeta?.name ?? "shop-partners-@name-fulfillment-administration-getting-started",
    path: getting_45startedRdWXpp6kGwMeta?.path ?? "/shop/partners/@:name()/fulfillment/administration/getting-started",
    meta: getting_45startedRdWXpp6kGwMeta || {},
    alias: getting_45startedRdWXpp6kGwMeta?.alias || [],
    redirect: getting_45startedRdWXpp6kGwMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/getting-started.vue").then(m => m.default || m)
  },
  {
    name: indexPUpft01QJkMeta?.name ?? "shop-partners-@name-fulfillment-administration",
    path: indexPUpft01QJkMeta?.path ?? "/shop/partners/@:name()/fulfillment/administration",
    meta: indexPUpft01QJkMeta || {},
    alias: indexPUpft01QJkMeta?.alias || [],
    redirect: indexPUpft01QJkMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/index.vue").then(m => m.default || m)
  },
  {
    name: indexhqLPuhAYswMeta?.name ?? "shop-partners-@name-fulfillment-administration-orders",
    path: indexhqLPuhAYswMeta?.path ?? "/shop/partners/@:name()/fulfillment/administration/orders",
    meta: indexhqLPuhAYswMeta || {},
    alias: indexhqLPuhAYswMeta?.alias || [],
    redirect: indexhqLPuhAYswMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLNdPmkcalMeta?.name ?? "shop-partners-@name-fulfillment-administration-warehouses-warehouseName-batches",
    path: indexvLNdPmkcalMeta?.path ?? "/shop/partners/@:name()/fulfillment/administration/warehouses/:warehouseName()/batches",
    meta: indexvLNdPmkcalMeta || {},
    alias: indexvLNdPmkcalMeta?.alias || [],
    redirect: indexvLNdPmkcalMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/warehouses/[warehouseName]/batches/index.vue").then(m => m.default || m)
  },
  {
    name: index967hFEmh7EMeta?.name ?? "shop-partners-@name-fulfillment-administration-warehouses-warehouseName-cases",
    path: index967hFEmh7EMeta?.path ?? "/shop/partners/@:name()/fulfillment/administration/warehouses/:warehouseName()/cases",
    meta: index967hFEmh7EMeta || {},
    alias: index967hFEmh7EMeta?.alias || [],
    redirect: index967hFEmh7EMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/warehouses/[warehouseName]/cases/index.vue").then(m => m.default || m)
  },
  {
    name: indexOfT7Z4KYaXMeta?.name ?? "shop-partners-@name-fulfillment-administration-warehouses-warehouseName-manifests",
    path: indexOfT7Z4KYaXMeta?.path ?? "/shop/partners/@:name()/fulfillment/administration/warehouses/:warehouseName()/manifests",
    meta: indexOfT7Z4KYaXMeta || {},
    alias: indexOfT7Z4KYaXMeta?.alias || [],
    redirect: indexOfT7Z4KYaXMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/warehouses/[warehouseName]/manifests/index.vue").then(m => m.default || m)
  },
  {
    name: indexuwuleru8e9Meta?.name ?? "shop-partners-@name-fulfillment-administration-warehouses-warehouseName-parcels",
    path: indexuwuleru8e9Meta?.path ?? "/shop/partners/@:name()/fulfillment/administration/warehouses/:warehouseName()/parcels",
    meta: indexuwuleru8e9Meta || {},
    alias: indexuwuleru8e9Meta?.alias || [],
    redirect: indexuwuleru8e9Meta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/warehouses/[warehouseName]/parcels/index.vue").then(m => m.default || m)
  },
  {
    name: indexc3yFMsRq9OMeta?.name ?? "shop-partners-@name-fulfillment-administration-warehouses-warehouseName-shipments",
    path: indexc3yFMsRq9OMeta?.path ?? "/shop/partners/@:name()/fulfillment/administration/warehouses/:warehouseName()/shipments",
    meta: indexc3yFMsRq9OMeta || {},
    alias: indexc3yFMsRq9OMeta?.alias || [],
    redirect: indexc3yFMsRq9OMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/warehouses/[warehouseName]/shipments/index.vue").then(m => m.default || m)
  },
  {
    name: createL8uG5IabbhMeta?.name ?? "shop-partners-@name-fulfillment-administration-warehouses-create",
    path: createL8uG5IabbhMeta?.path ?? "/shop/partners/@:name()/fulfillment/administration/warehouses/create",
    meta: createL8uG5IabbhMeta || {},
    alias: createL8uG5IabbhMeta?.alias || [],
    redirect: createL8uG5IabbhMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/warehouses/create.vue").then(m => m.default || m)
  },
  {
    name: index7rfPPiUDqYMeta?.name ?? "shop-partners-@name-fulfillment-administration-warehouses",
    path: index7rfPPiUDqYMeta?.path ?? "/shop/partners/@:name()/fulfillment/administration/warehouses",
    meta: index7rfPPiUDqYMeta || {},
    alias: index7rfPPiUDqYMeta?.alias || [],
    redirect: index7rfPPiUDqYMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/administration/warehouses/index.vue").then(m => m.default || m)
  },
  {
    name: indexCo3Io1X6tQMeta?.name ?? "shop-partners-@name-fulfillment",
    path: indexCo3Io1X6tQMeta?.path ?? "/shop/partners/@:name()/fulfillment",
    meta: indexCo3Io1X6tQMeta || {},
    alias: indexCo3Io1X6tQMeta?.alias || [],
    redirect: indexCo3Io1X6tQMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/index.vue").then(m => m.default || m)
  },
  {
    name: register9hzCUwMdOxMeta?.name ?? "shop-partners-@name-fulfillment-register",
    path: register9hzCUwMdOxMeta?.path ?? "/shop/partners/@:name()/fulfillment/register",
    meta: register9hzCUwMdOxMeta || {},
    alias: register9hzCUwMdOxMeta?.alias || [],
    redirect: register9hzCUwMdOxMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/register.vue").then(m => m.default || m)
  },
  {
    name: termsFxt0N3s1YFMeta?.name ?? "shop-partners-@name-fulfillment-terms",
    path: termsFxt0N3s1YFMeta?.path ?? "/shop/partners/@:name()/fulfillment/terms",
    meta: termsFxt0N3s1YFMeta || {},
    alias: termsFxt0N3s1YFMeta?.alias || [],
    redirect: termsFxt0N3s1YFMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/terms.vue").then(m => m.default || m)
  },
  {
    name: index37C6gatoJEMeta?.name ?? "shop-partners-@name-fulfillment-warehouses-id.vue",
    path: index37C6gatoJEMeta?.path ?? "/shop/partners/@:name()/fulfillment/warehouses/:id().vue",
    meta: index37C6gatoJEMeta || {},
    alias: index37C6gatoJEMeta?.alias || [],
    redirect: index37C6gatoJEMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/fulfillment/warehouses/[id].vue/index.vue").then(m => m.default || m)
  },
  {
    name: index2TaIOUUaaIMeta?.name ?? "shop-partners-@name",
    path: index2TaIOUUaaIMeta?.path ?? "/shop/partners/@:name()",
    meta: index2TaIOUUaaIMeta || {},
    alias: index2TaIOUUaaIMeta?.alias || [],
    redirect: index2TaIOUUaaIMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/index.vue").then(m => m.default || m)
  },
  {
    name: indexM76ro9yObpMeta?.name ?? "shop-partners-@name-partners",
    path: indexM76ro9yObpMeta?.path ?? "/shop/partners/@:name()/partners",
    meta: indexM76ro9yObpMeta || {},
    alias: indexM76ro9yObpMeta?.alias || [],
    redirect: indexM76ro9yObpMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/partners/index.vue").then(m => m.default || m)
  },
  {
    name: _91category_93UmOfGE4w6tMeta?.name ?? "shop-partners-@name-products-category",
    path: _91category_93UmOfGE4w6tMeta?.path ?? "/shop/partners/@:name()/products/:category()",
    meta: _91category_93UmOfGE4w6tMeta || {},
    alias: _91category_93UmOfGE4w6tMeta?.alias || [],
    redirect: _91category_93UmOfGE4w6tMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/products/[category].vue").then(m => m.default || m)
  },
  {
    name: index4V1QD4dMUXMeta?.name ?? "shop-partners-@name-products",
    path: index4V1QD4dMUXMeta?.path ?? "/shop/partners/@:name()/products",
    meta: index4V1QD4dMUXMeta || {},
    alias: index4V1QD4dMUXMeta?.alias || [],
    redirect: index4V1QD4dMUXMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/@[name]/products/index.vue").then(m => m.default || m)
  },
  {
    name: registerckrmyHSAzhMeta?.name ?? "shop-partners-register",
    path: registerckrmyHSAzhMeta?.path ?? "/shop/partners/register",
    meta: registerckrmyHSAzhMeta || {},
    alias: registerckrmyHSAzhMeta?.alias || [],
    redirect: registerckrmyHSAzhMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/register.vue").then(m => m.default || m)
  },
  {
    name: termsSK6XvqXSx1Meta?.name ?? "shop-partners-terms",
    path: termsSK6XvqXSx1Meta?.path ?? "/shop/partners/terms",
    meta: termsSK6XvqXSx1Meta || {},
    alias: termsSK6XvqXSx1Meta?.alias || [],
    redirect: termsSK6XvqXSx1Meta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/partners/terms.vue").then(m => m.default || m)
  },
  {
    name: _91id_93DjVsKdZ4vIMeta?.name ?? "shop-product-id",
    path: _91id_93DjVsKdZ4vIMeta?.path ?? "/shop/product/:id()",
    meta: _91id_93DjVsKdZ4vIMeta || {},
    alias: _91id_93DjVsKdZ4vIMeta?.alias || [],
    redirect: _91id_93DjVsKdZ4vIMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/product/[id].vue").then(m => m.default || m)
  },
  {
    name: _91category_93OdJeGkDxjcMeta?.name ?? "shop-products-category",
    path: _91category_93OdJeGkDxjcMeta?.path ?? "/shop/products/:category()",
    meta: _91category_93OdJeGkDxjcMeta || {},
    alias: _91category_93OdJeGkDxjcMeta?.alias || [],
    redirect: _91category_93OdJeGkDxjcMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/products/[category].vue").then(m => m.default || m)
  },
  {
    name: indexJxBz4rn3XZMeta?.name ?? "shop-shoppers",
    path: indexJxBz4rn3XZMeta?.path ?? "/shop/shoppers",
    meta: indexJxBz4rn3XZMeta || {},
    alias: indexJxBz4rn3XZMeta?.alias || [],
    redirect: indexJxBz4rn3XZMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/shoppers/index.vue").then(m => m.default || m)
  },
  {
    name: indexVDBd912gKIMeta?.name ?? "shop-stores-@name",
    path: indexVDBd912gKIMeta?.path ?? "/shop/stores/@:name()",
    meta: indexVDBd912gKIMeta || {},
    alias: indexVDBd912gKIMeta?.alias || [],
    redirect: indexVDBd912gKIMeta?.redirect,
    component: () => import("C:/Users/Administrator/Desktop/lsdope/pages/shop/stores/@[name]/index.vue").then(m => m.default || m)
  }
]